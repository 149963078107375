import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, Wrapper, Article, CanvasAnimation } from '../components';
import PageProps from '../models/PageProps';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import { media } from '../utils/media';
import rgba from 'polished/lib/color/rgba';
import darken from 'polished/lib/color/darken';
import lighten from 'polished/lib/color/lighten';

const Home = styled.main`
  margin: 5rem auto;
  max-width: 100rem;
  width: 100%;
  padding: 3rem 6rem;
  @media ${media.tablet} {
    height: 100%;
    padding: 3rem 3rem;
  }
  @media ${media.phone} {
    height: 100%;
    padding: 2rem 1.5rem;
  }
`;

export default class IndexPage extends React.Component<PageProps> {
  public render() {
    const { data } = this.props;
    const { edges, totalCount } = data.allMarkdownRemark;
    return (
      <Layout>
        <Wrapper fullWidth={true}>
          <Helmet title={`Home | ${config.siteTitle}`} />
          <CanvasAnimation />
          <Home>
            <p>
              雑記です
              <br />
              発言は個人の見解であり、所属する組織の公式見解ではありません
            </p>
            <h2>Hitoshi Kojima</h2>
            <p>
              I am a software engineer. <br />
              Current - Engineering Manager at Mercari inc.
            </p>
            <h2>Latest Post</h2>
            {edges.map(post => (
              <Article
                title={post.node.frontmatter.title}
                date={post.node.frontmatter.date}
                excerpt={post.node.excerpt}
                timeToRead={post.node.timeToRead}
                slug={post.node.fields.slug}
                category={post.node.frontmatter.category}
                key={post.node.fields.slug}
              />
            ))}
            <p>
              <Link to={'/blog'}>Previous Posts: {totalCount}</Link>
            </p>
          </Home>
        </Wrapper>
      </Layout>
    );
  }
}
export const IndexQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            category
          }
          timeToRead
        }
      }
    }
  }
`;
